<template>
<div>

    <modal-loading :processing="processing" :messageProcessing="messageProcessing"></modal-loading>

    <div>
        <s-toolbar close @save="save()" save :label="'Cotizaciòn'" @close="$emit('onClose')" />
        <v-card>
            <v-col>
                <v-row>
                    <v-col lg="4" md="4" cols="12">
                        <v-col class="pr-0 pl-0">
                            <v-row>
                                <v-card outlined width="100%" style="margin-top:10px">
                                    <v-card-title style="margin-top: -10px; margin-bottom: -10px; font-family: Calibri">
                                        Datos Generales. <b style="color:red" class="ml-1">{{msgVisit}}</b>
                                    </v-card-title>
                                </v-card>
                            </v-row>
                        </v-col>
                        <v-col class="pr-0 pl-0">
                            <v-row>
                                <v-card outlined width="100%" style="margin-top:10px">
                                    <v-row style="margin:auto">

                                        <v-col lg="6" md="6" cols="6">
                                            <s-date disabled label="Fecha Solicitud" />
                                        </v-col>
                                        <v-col lg="6" md="6" cols="6">
                                            <s-text label="Duracion P.(día)" :autofocus="true" number :min=1 v-model="itemHeaders.CttDayExpiration" ref="CtpRuc"></s-text>
                                        </v-col>
                                        <v-col lg="6" md="6" cols="12">
                                            <v-checkbox style="margin-top:-15px" color="red" hide-details label="Requiere de visita a campo" v-model="itemHeaders.CttRequiredVisit"></v-checkbox>
                                        </v-col>
                                        <v-col lg="6" md="6" cols="12" style="margin-top:-15px" v-if="itemHeaders.CttRequiredVisit">
                                            <s-date label="Fecha Programada Visita" v-model="itemHeaders.CttScheduledDate"></s-date>
                                            <!-- <v-time-picker></v-time-picker> -->
                                        </v-col>
                                    </v-row>

                                </v-card>
                            </v-row>
                        </v-col>

                    </v-col>
                    <v-col lg="8" md="8" cols="12">
                        <v-col class="pr-0 pl-0">
                            <v-row>
                                <v-card outlined width="100%" style="margin-top:10px" class="ml-3">
                                    <v-card-title style="margin-top: -10px; margin-bottom: -10px; font-family: Calibri">
                                        Participantes<span>
                                            <v-checkbox hide-details="" v-model="Check"></v-checkbox>
                                        </span>
                                    </v-card-title>
                                </v-card>
                            </v-row>
                        </v-col>
                        <v-col class="pr-0 pl-0">
                            <v-row>
                                <v-card outlined width="100%" style="margin-top:10px" class="ml-3">

                                    <v-row style="margin:auto">

                                        <v-col cols="6" lg="3" md="3" v-if="!Check">
                                            <s-text :outlined="false" label="Ruc Participante" number :autofocus="true" v-model="item.CtpRuc" ref="CtpRuc" @keyupEnter="searchSunarp">
                                                <template v-slot:prepend>
                                                    <v-icon @click="searchSunarp($event)">mdi-magnify</v-icon>
                                                </template>
                                            </s-text>
                                        </v-col>
                                        <v-col cols="6" lg="3" md="3" v-if="Check">
                                            <v-supplier :outlined="false" label="Proveedor" v-model="Supplier" :value="Supplier" />
                                            <span v-if="ProviderBloqueado > 0" style="color: red; font-family: Calibri; font-size: 10px">Proveedor en Lista Negra</span>
                                        </v-col>
                                        <v-col cols="6" lg="4" md="4">
                                            <s-text v-if="Check" :outlined="false" label="Ruc Participante" v-model="item.CtpRuc" ref="CtpName"></s-text>
                                            <s-text v-if="!Check" :outlined="false" label="Nombre Participante" v-model="item.CtpName" ref="CtpName"></s-text>
                                        </v-col>
                                        <v-col cols="6" lg="3" md="3">
                                            <s-text :outlined="false" label="E-mail" v-model="item.CtpEmail" ref="CtpEmail"></s-text>
                                        </v-col>
                                        <v-col cols="6" lg="2" md="2" class="mt-3" v-if="this.item.ProviderBloqueado == 0">
                                            <v-btn class="capitalize save" color="#2f5985" fab dark x-small elevation="0" @click="add()">
                                                <v-icon style="font-size:14px; border-radius: 10px" class="mr-0">mdi-plus-circle-outline</v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>

                                </v-card>
                            </v-row>
                        </v-col>
                        <v-row>
                            <v-col>
                                <v-data-table class="elevation-0 table table-hover table-condensed table-bordered" style="font-family:" dense @click:row="rowClickParticipants" :headers="headersParticipants" :items-per-page="-1" v-model="selectedParticipants" :items="itemsParticipants" item-key="DerNumLine" :height="'auto'" hide-default-footer>
                                    <!-- Removed -->
                                    <template v-slot:item.Removed="props">
                                        <v-btn text small @click="removed(props)">
                                            <v-icon style="font-size:18px; color:red">mdi-minus-circle-outline</v-icon>
                                        </v-btn>
                                    </template>

                                </v-data-table>
                            </v-col>
                        </v-row>

                    </v-col>

                    <v-divider vertical></v-divider>
                    <v-col lg="12" cols="12">
                        <v-col class="pr-0 pl-0">
                            <v-row>
                                <v-card outlined width="100%" style="margin-top:10px">
                                    <v-card-title style="margin-top: -10px; margin-bottom: -10px; font-family: Calibri">
                                        Items Requeridos
                                    </v-card-title>
                                </v-card>
                            </v-row>
                        </v-col>
                        <v-row>
                            <v-col>
                                <v-data-table class="elevation-0 table table-hover table-condensed table-bordered" style="font-family:" dense @click:row="rowClick" :headers="headersSAP" :items-per-page="-1" v-model="selectedSAP" :items="itemsSAP" item-key="Line" :height="'auto'" hide-default-footer>
                                    <template v-slot:item.CtdObservation="props">
                                        <s-text label="" v-model="props.item.CtdObservation"></s-text>
                                    </template>
                                    <template v-slot:item.OddDescription="props">
                                        <s-text label="" v-model="props.item.OddDescription"></s-text>
                                    </template>
                                    <template v-slot:item.DerQuantity="props">
                                        <s-text decimal label="" v-model="props.item.DerQuantity"></s-text>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>

                    </v-col>

                </v-row>
            </v-col>
        </v-card>
    </div>
</div>
</template>

<script>
//Services
import _sCotization from "@/services/Logistics/CotizationService";
import _sSunarp from "@/services/General/GenSunarpService";
import _sSupplier from "@/services/Logistics/SupplierService";

//Components
import vSupplier from "@/views/Logistics/vSupplier.vue";

export default {
    components: {
        vSupplier
    },
    props: {
        order: {},
    },
    data: () => ({
        processing: false,
        messageProcessing: "",
        selectedSAP: [],
        itemsSAP: [],
        headersSAP: [{
                text: "Descripción",
                value: "OddDescription",
                sortable: false,
                width: 640,
            },
            {
                text: "Cant",
                value: "DerQuantity",
                sortable: false,
            },

            {
                text: "Und",
                value: "UnitMeasurement",
                sortable: false,
            },
            {
                text: "Comentario",
                value: "CtdObservation",
                sortable: false,
            },
        ],

        selectedParticipants: [],
        itemsParticipants: [],
        headersParticipants: [{
                text: "X",
                value: "Removed",
                sortable: false,
                align: "center",
                width: 10
            },
            {
                text: "Doc. Part.",
                value: "CtpRuc",
                sortable: false,
            },
            {
                text: "Nombre Part.",
                value: "CtpName",
                sortable: false,
            },
            {
                text: "Correo",
                value: "CtpEmail",
                sortable: false
            },
        ],

        item: {
            DerNumLine: 0,
            CtpRuc: null,
            CtpName: null,
            CtpEmail: null,
            ProviderBloqueado: 0
        },
        itemHeaders: {
            CttDayExpiration: 10,
            ReqID: null
        },
        msgVisit: null,
        Supplier: null,
        ProviderBloqueado: 0,
        Check: true
    }),

    created() {

        this.itemsSAP = this.order.detail;
        if (this.itemsSAP)
            for (let i = 0; i < this.itemsSAP.length; i++) {
                this.itemsSAP[i].Line = i + 1;

                if (this.itemsSAP[i].RequiredVisit == true) {
                    this.itemHeaders.CttRequiredVisit = true

                    this.msgVisit = "Uno(s) de los items requiere visita a campo. "
                }

                this.itemHeaders.CttDayExpiration = this.itemsSAP[i].ReqDayExpiration
            }

    },

    watch: {
        itemsParticipants() {
            if (this.itemsParticipants)
                for (let i = 0; i < this.itemsParticipants.length; i++)
                    this.itemsParticipants[i].DerNumLine = i + 1;
        },

        'item.CtpRuc'() {
            this.processing = false
            this.messageProcessing = ""
            console.log("this.item.CtpRuc", this.processing, this.messageProcessing)
        },
        Supplier() {
            if (this.Supplier != null)
                if (this.Supplier.SupID != null) {
                    this.item = {
                        CtpRuc: this.Supplier.SupID,
                        CtpName: this.Supplier.SupName,
                        CtpEmail: this.Supplier.ConEmail,
                        ProviderBloqueado: this.Supplier.ProviderBloqueado

                    }
                    this.ProviderBloqueado = this.Supplier.ProviderBloqueado;
                }
        },
        Check() {
            this.item = {
                DerNumLine: 0,
                CtpRuc: null,
                CtpName: null,
                CtpEmail: null,
                ProviderBloqueado: 0
            }
            this.Supplier.SupName = null
        }
    },
    methods: {

        //***************************************** Item Detalle ******************************************************************** */
        //Seleccionar Fila
        rowClick: function (item, row) {
            this.selectedSAP = [item];
        },

        rowClickParticipants: function (item, row) {
            this.selectedParticipants = [item];
        },

        add() {

            if (this.item.CtpRuc == null || this.item.CtpRuc == "" || this.item.CtpRuc.length == 0) {
                this.$refs.CtpRuc.error("Ingrese Ruc")
                return
            }

            if (this.item.CtpRuc.length < 8 || this.item.CtpRuc.length > 11) {
                this.$refs.CtpRuc.error("Documento ingresado no es correcto")
                return
            }

            if (this.item.ProviderBloqueado > 0) {
                this.$fun.alert("[IMP]. Proveedor en Lista Negra", "warning")
                return
            }

            if (this.item.CtpName == null || this.item.CtpName == "" || this.item.CtpName.length == 0) {
                this.$refs.CtpName.error("Ingrese Nombre de Participante")
                return
            }

            if (this.item.CtpName.length < 3) {
                this.$refs.CtpName.error("Nombre ingresado incorrecto")
                return
            }

            if (this.item.CtpEmail == null || this.item.CtpEmail == "" || this.item.CtpEmail.length == 0) {
                this.$refs.CtpEmail.error("Ingrese Email")
                return
            }

            if (!this.$fun.isValidEmail(this.item.CtpEmail)) {
                this.$refs.CtpEmail.error("Email invalido")
                return
            }

            if (
                this.itemsParticipants.find(
                    (x) =>
                    x.CtpRuc == this.item.CtpRuc
                )
            ) {
                this.$fun.alert("Ruc de Participante ya fue agregada", "warning");
                return;
            }
            this.itemsParticipants.push(this.item)
            this.item = {}
            this.Supplier.SupName =  null

        },

        removed(props) {

            if (this.itemsParticipants.length > 0) {
                this.itemsParticipants = this.itemsParticipants.filter(
                    x => x.CtpRuc != props.item.CtpRuc,
                );
            }
        },

        searchSunarp() {

            if (this.item.CtpRuc == null || this.item.CtpRuc.length == 0) {
                return
            }

            if(this.item.CtpRuc.length < 11){
                alert("Nro. RUC inválido.")
                return
            }

            this.messageProcessing = ""
            this.processing = true

            this.item.CtpName = null
            this.item.CtpEmail = null

            _sSupplier.byRuc(this.item.CtpRuc, this.$fun.getUserID()).then(r => {
                    if (r.status == 200) {

                        var objProvider = r.data;
                        console.log("dataaaaaaaaaaa", objProvider)

                        if (objProvider == null) {

                            _sSunarp.searchsunarp(this.item.CtpRuc, this.$fun.getUserID()).then(resp => {
                                    console.log(resp.data)
                                    if (resp.status == 200) {
                                        this.item.ProviderBloqueado = 0
                                        this.item.CtpName = resp.data.SunName
                                        this.processing = false
                                    }
                                },
                                (e) => {
                                    this.messageProcessing = e.response.data.Message;
                                    this.processing = false;
                                    this.item.CtpName = ""
                                });
                        } else {
                            this.item.ProviderBloqueado = objProvider.ProviderBloqueado

                            if (objProvider.ProviderBloqueado > 0) {
                                this.$fun.alert("Proveedor [" + objProvider.SupName + "] en Lista Negra", "warning")
                                this.messageProcessing = "";
                                this.processing = false;
                                return
                            } else {
                                this.item = {
                                    CtpRuc: objProvider.SupID,
                                    CtpName: objProvider.SupName,
                                    CtpEmail: objProvider.ConEmail,
                                    ProviderBloqueado: objProvider.ProviderBloqueado

                                }

                                this.processing = false;
                                this.messageProcessing = "";

                            }
                        }

                    }
                },
                (e) => {
                    this.messageProcessing = e.response.data.Message;
                    this.processing = false;
                    this.item.CtpName = ""
                });

        },

        save() {

            this.itemHeaders.CttRequiredVisit = this.itemHeaders.CttRequiredVisit ? 1 : null

            if (this.itemsParticipants.length <= 0) {
                this.$fun.alert("No existen participantes en lista", "warning")
                return
            }

            if (this.itemsSAP.length <= 0) {
                this.$fun.alert("No existen items en lista para cotizar", "warning")
                return
            }

            if (this.itemHeaders.CttDayExpiration <= 0) {
                this.$fun.alert("Dias ingresado no esta permitido", "warning")
                return
            }

            if(this.itemHeaders.CttScheduledDate < this.$fun.getDate() && this.itemHeaders.CttRequiredVisit){
                this.$fun.alert("Fecha Programada no puede ser inferior a la fecha actual.", "warning")
                return
            }

            if(!this.itemHeaders.CttRequiredVisit){
                this.itemHeaders.CttScheduledDate = null;
            }

            this.itemsSAP.forEach(element => {
                element.CtdDate = this.itemHeaders.CttDate
                element.SecStatus = 1
                element.UsrCreateID = this.$fun.getUserID()
                element.CtdDescription = element.OddDescription
                element.CtdQuantity = element.DerQuantity
            });

            this.itemsParticipants.forEach(element => {
                element.CtpDate = this.itemHeaders.CttDate
                element.SecStatus = 1
                element.UsrCreateID = this.$fun.getUserID()
            });

            this.itemHeaders.ReqID = this.order.ReqID
            this.itemHeaders.TypeOrder = this.order.TypeOrder
            this.itemHeaders.SecStatus = 1
            this.itemHeaders.UsrCreateID = this.$fun.getUserID()

            this.itemHeaders.itemsSAP = this.itemsSAP
            this.itemHeaders.itemsParticipants = this.itemsParticipants
            console.log("save", this.itemHeaders,this.itemsSAP)

            this.$fun.alert("¿Seguro de continuar con la Cotizacion?", "question", false).then(val => {
                if (val.value) {
                    this.messageProcessing = ""
                    this.processing = true
                    _sCotization.save(this.itemHeaders, this.$fun.getUserID()).then(resp => {
                            if (resp.status == 200) {
                                this.processing = false
                                this.$fun.alert("Proceso terminado existosamente", "success", false)
                                this.$emit('onClose')
                                console.log(resp.data)
                            }
                        },
                        (e) => {
                            this.messageProcessing = e.response.data.Message;
                            this.processing = false;
                        });
                }
            })
        }

    }
};
</script>
