<template>
<div>

    <!-- <v-dialog v-if="processing" v-model="processing" :persistent="messageProcessing.length==0" width="400">
        <v-card color="primary" dark>
            <v-card-text v-if="messageProcessing.length == 0">
                Procesando, Por favor espere...
                <v-progress-linear indeterminate color="white" class="mb-0 mt-2"></v-progress-linear>
            </v-card-text>
            <v-card-text v-else>
                <div class="text--white pt-4">
                    Error.. <br />
                    {{ messageProcessing }}
                </div>
            </v-card-text>
        </v-card>
    </v-dialog> -->
    <modal-loading :processing="processing" :messageProcessing="messageProcessing"></modal-loading>

    <div>
        <s-toolbar close @save="save()" save :label="'Cotizaciòn'" @close="$emit('onClose')" />
        <v-card>
            <v-col>
                <v-row>
                    <v-col lg="12" md="12" cols="12">
                        <v-col class="pr-0 pl-0">
                            <v-row>
                                <v-card outlined width="100%" style="margin-top:10px">
                                    <v-card-title style="margin-top: -10px; margin-bottom: -10px; font-family: Calibri">
                                        Datos Generales. <b style="color:red" class="ml-1">{{msgVisit}}</b>
                                    </v-card-title>
                                </v-card>
                            </v-row>
                        </v-col>
                        <v-col class="pr-0 pl-0">
                            <v-row>
                                <v-card outlined width="100%" style="margin-top:10px">
                                    <v-row style="margin:auto">

                                        <v-col lg="6" md="6" cols="6">
                                            <s-date disabled label="Fecha Solicitud" />
                                        </v-col>
                                    </v-row>

                                </v-card>
                            </v-row>
                        </v-col>

                    </v-col>
                    <v-divider vertical></v-divider>
                    <v-col lg="12" cols="12">
                        <v-col class="pr-0 pl-0">
                            <v-row>
                                <v-card outlined width="100%" style="margin-top:10px">
                                    <v-card-title style="margin-top: -10px; margin-bottom: -10px; font-family: Calibri">
                                        Items Requeridos
                                    </v-card-title>
                                </v-card>
                            </v-row>
                        </v-col>
                        <v-row>
                            <v-col>
                                <v-data-table class="elevation-0 table table-hover table-condensed table-bordered" style="font-family:" dense @click:row="rowClick" :headers="headersSAP" :items-per-page="-1" v-model="selectedSAP" :items="itemsSAP" item-key="Line" :height="'auto'" hide-default-footer>
                                    <template v-slot:item.CtdObservation="props">
                                        <s-text label="" v-model="props.item.CtdObservation"></s-text>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>

                    </v-col>

                </v-row>
            </v-col>
        </v-card>
    </div>
</div>
</template>

<script>
//Services
import _sCotization from "@/services/Logistics/CotizationService";
import _sSunarp from "@/services/General/GenSunarpService";
import _sSupplier from "@/services/Logistics/SupplierService";

export default {
    components: {},
    props: {
        order: {},
    },
    data: () => ({
        processing: false,
        messageProcessing: "",
        selectedSAP: [],
        itemsSAP: [],
        headersSAP: [{
                text: "Descripción",
                value: "OddDescription",
                sortable: false,
                width: 640,
            },
             {
                 text: "N° Articulo",
                 value: "ArtCode",
                 sortable: false
             },
            {
                text: "Cant",
                value: "DerQuantity",
                sortable: false,
            },

            {
                text: "Und",
                value: "UnitMeasurement",
                sortable: false,
            },
            {
                text: "Comentario",
                value: "CtdObservation",
                sortable: false,
            },
        ],

        itemHeaders: {
            CttDayExpiration: 10,
            ReqID: null
        },
    }),

    created() {

        this.itemsSAP = this.order.detail;

    },

    watch: {
    },
    methods: {

        //***************************************** Item Detalle ******************************************************************** */
        //Seleccionar Fila
        rowClick: function (item, row) {
            this.selectedSAP = [item];
        },


        save() {

            this.itemHeaders.CttRequiredVisit = this.itemHeaders.CttRequiredVisit ? 1 : null

            if (this.itemsParticipants.length <= 0) {
                this.$fun.alert("No existen participantes en lista", "warning")
                return
            }

            if (this.itemsSAP.length <= 0) {
                this.$fun.alert("No existen items en lista para cotizar", "warning")
                return
            }

            if (this.itemHeaders.CttDayExpiration <= 0) {
                this.$fun.alert("Dias ingresado no esta permitido", "warning")
                return
            }

            this.itemsSAP.forEach(element => {
                /* element.CtdDescription = element.DerDescription
                element.CtdQuantity = element.DerQuantity
                element.CtdObservation = element.OddObservation */
                element.CtdDate = this.itemHeaders.CttDate
                element.SecStatus = 1
                element.UsrCreateID = this.$fun.getUserID()
            });

            this.itemsParticipants.forEach(element => {
                element.CtpDate = this.itemHeaders.CttDate
                element.SecStatus = 1
                element.UsrCreateID = this.$fun.getUserID()
            });

            this.itemHeaders.ReqID = this.order.ReqID
            this.itemHeaders.TypeOrder = this.order.TypeOrder
            this.itemHeaders.SecStatus = 1
            this.itemHeaders.UsrCreateID = this.$fun.getUserID()

            this.itemHeaders.itemsSAP = this.itemsSAP
            this.itemHeaders.itemsParticipants = this.itemsParticipants
            //this.itemHeaders.SendMail = 1
            console.log(this.itemHeaders)

            this.$fun.alert("¿Seguro de continuar con la Cotizacion?", "question", false).then(val => {
                if (val.value) {
                    this.messageProcessing = ""
                    this.processing = true
                    _sCotization.save(this.itemHeaders, this.$fun.getUserID()).then(resp => {
                            if (resp.status == 200) {
                                this.processing = false
                                this.$fun.alert("Proceso terminado existosamente", "success", false)
                                this.$emit('onClose')
                                console.log(resp.data)
                            }
                        },
                        (e) => {
                            this.messageProcessing = e.response.data.Message;
                            this.processing = true;
                        });
                }
            })

            /* console.log(this.itemsSAP) 
            console.log(this.itemsParticipants)  */

        }

    }
};
</script>
