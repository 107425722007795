<template>
<div>

    <modal-loading :processing="processing" :messageProcessing="messageProcessing"></modal-loading>

    <div>
        <v-dialog v-if="dialogLoad" v-model="dialogLoad" persistent transition="dialog-bottom-transition">
            <order-edit @onClose="dialogLoad = false;refresh();filter.TypeRequirement = null" @clearItems='  itemsSAP=[];' @onCloseClear="dialogLoad = false;refresh();itemsSAP=[];" :typeOrder="filter.TypeRequirement" :order="order" :value="null"></order-edit>
        </v-dialog>
    </div>

    <div>
        <v-dialog v-if="dialogCotizar" v-model="dialogCotizar" persistent transition="dialog-bottom-transition">
            <order-cotization-new @onClose="dialogCotizar = false; refresh();" :order="order"></order-cotization-new>
        </v-dialog>
    </div>

    <v-dialog v-if="dialogReq" v-model="dialogReq" persistent width="100%">
        <v-card>
            <lgs-requirement-edit :value="ReqID" @close="dialogReq = false"></lgs-requirement-edit>
        </v-card>
    </v-dialog>

    <!-- <div>
        <v-dialog v-if="dialogDispach" v-model="dialogDispach" persistent transition="dialog-bottom-transition">
            <order-dispatch-new @onClose="dialogDispach = false; refresh();" :order="order" />
        </v-dialog>
    </div> -->

    <s-toolbar dark :remove="itemsSAP.length >0" @removed=deleted(itemsSAP) search-input label="Atención de Requerimientos || Compra/Servicios">

        <template v-slot:options>
            <!-- <div>
                <v-btn title="Despachar" elevation="0" x-small color="#5175af" v-if="$fun.isAdmin()" @click="orderDispatch()">
                    <i class="fas fa-building"></i>
                </v-btn>
            </div> -->
            <v-btn elevation="0" small icon class="mr-0" @click="previewCotizar()" title="Generar Cotización" v-if="$fun.isAdmin()">
                <v-icon style="font-size:14px; border-radius: 10px"> fas fa-comments-dollar</v-icon>
            </v-btn>
            <v-btn elevation="0" small dark icon class="ml-0 mr-0" @click="previewSap()" v-if="$fun.isAdmin()" title="Generar Orden de Compra / Orden de Servicio">
                <v-icon style="font-size:14px; border-radius: 10px">mdi mdi-cart</v-icon>
            </v-btn>
        </template>
    </s-toolbar>

    <v-card elevation="0" outlined>
        <v-container>
            <v-col style="" class="pt-0">
                <v-row justify="center">
                    <v-col lg="4" md="4" cols="6" class="s-col-form">
                        <s-select-definition label="Empresa" v-model="filter.typeBusiness" :def="1142" />
                    </v-col>
                    <v-col lg="4" md="4" cols="6" class="s-col-form">
                        <s-select label="Tipo Requerimiento" :outlined="true" :items="[
                                        { value: 1, text: 'Compras' },
                                        { value: 3, text: 'Servicio' },
                                    ]" v-model="filter.TypeRequirement" clearable>
                        </s-select>
                    </v-col>

                    <v-col lg="4" md="4" cols="6" class="s-col-form">
                        <s-select-definition :def="1183" v-model="filter.TypeEAR" label="Tipo" clearable></s-select-definition>
                    </v-col>

                    <v-col lg="4" md="4" cols="6" class="s-col-form" v-if="$fun.isAdmin()">
                        <c-select-area full clearable noDefault label="Filtro por Área" v-model="filter.AreID" :typeArea="3" > </c-select-area>
                    </v-col>
                    <v-col :lg="$fun.isAdmin() ? 4: 6" :md="$fun.isAdmin() ? 4: 6" cols="6" class="s-col-form">
                        <s-date label="Fecha Inicio" clearable v-model="filter.BeginDate"></s-date>
                    </v-col>
                    <v-col :lg="$fun.isAdmin() ? 4: 6" :md="$fun.isAdmin() ? 4: 6" cols="6" class="s-col-form">
                        <s-date label="Fecha Fin" clearable v-model="filter.EndDate"></s-date>
                    </v-col>
                </v-row>
            </v-col>
        </v-container>
    </v-card>

    <b>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details style="margin-top: 5px"></v-text-field>
    </b>
    <v-data-table class="elevation-0 table table-hover table-condensed table-bordered" disable-sort :search="search" show-group-by fixed-header :items=itemsDetail v-model="itemsSAP" height="330px" dense show-select item-key="DerID" :headers=config.headers :footer-props="{
            showCurrentPage: true,
            showFirstLastPage: true,
            itemsPerPageOptions: [200],
          }">

        <template v-slot:item.TypeRequirement="{ item }">
            <v-icon :color="
                                    item.TypeRequirement == 1 ? 'red' : 'green'
                                " style="font-size:16px;">
                {{
                                item.TypeRequirement == 1 ? 'fas fa-car': 'fas fa-street-view'
                            }}

            </v-icon>
            <b style="margin-left:10px">{{item.TypeRequirement == 1 ? 'Compra': 'Servicio'}}</b>
        </template>

        <template v-slot:item.ReqAuthorizationDate="{item}">
            <v-icon x-small :color="item.ReqTimeElapsedDay >= 10 ? 'error' : item.ReqTimeElapsedDay >= 3 ? 'warning' : 'success'"> mdi-checkbox-blank-circle</v-icon>
            {{$moment(item.ReqAuthorizationDate).format($const.FormatDateTimeDB)}}
        </template>

        <template v-slot:item.RequiredVisit="{item}">
            <v-chip x-small v-if="item.RequiredVisit" color="info">{{item.RequiredVisit ? 'Visita Campo' : ''}}</v-chip>
        </template>
        <template v-slot:item.ReqDocumentNumber="{item}">
            <a style="text-decoration:underline" @click="openReq(item)">{{item.ReqDocumentNumber}}</a>
        </template>

    </v-data-table>
</div>
</template>

<script>
//Services
import _sRequirementDetail from "@/services/Logistics/RequirementDetailService";

//Components
import OrderEdit from "@/views/Logistics/OrderPurchase/OrderPurchaseEdit.vue";
import OrderCotizationNew from '../OrderCotization/OrderCotizationNew.vue';
import OrderDispatchNew from '../OrderDispatch/OrderDispatchNew.vue';
import LgsRequirementEdit from "@/views/Logistics/Requirement/LgsRequirementEdit";

export default {
    name: "OrderPurchaseAttention",
    components: {
        OrderEdit,
        OrderCotizationNew,
        OrderDispatchNew,
        LgsRequirementEdit
    },
    props: {
        model: false
    },
    data: () => ({
        processing: false,
        messageProcessing: "",
        search: "",
        itemsDetail: [],
        showQuoteEdit: false,
        filter: {
            AreID: 0,
            BeginDate: null,
            EndDate: null,
            TypeRequirement: null,
            typeBusiness: 1,
            TypeEAR: null
        },
        config: {
            service: _sRequirementDetail,
            model: {
                DerID: "ID",
                ReqDocumentNumber: "string",
                ReqAuthorizationDate: "date",
                ReqDate: "date",
                ReqAccount: "string",
                ImpDiscount: "double",
                AvgPrice: "numeric",
                DiscPrcnt: "numeric",
                OddNumberLine: 0,
                DerStatus: ''
            },
            headers: [{
                    text: "Id",
                    value: "ReqID",
                    width: 10
                },
                {
                    text: "Tipo",
                    value: "TypeRequirement",
                    width: 100,
                },
                {
                    text: "N° Requerimiento",
                    value: "ReqDocumentNumber",
                    width: 160,
                },
                /* {
                    text: "N° articulo",
                    value: "ArtCode",
                    width: 150,
                }, */
                {
                    text: "Artículo",
                    value: "DerDescription",
                    width: 330,
                },
                {
                    text: "UM",
                    value: "UnitMeasurement",
                    width: 70,
                },
                {
                    text: "Cant. Solic.",
                    value: "DerQuantity",
                    width: 90,
                },
                {
                    text: "Area",
                    value: "AreName",
                },
                {
                    text: "Solicitado Por",
                    value: "UsrName",
                    width: 120,
                },

                
                {
                    text: "F.Autorizacion",
                    value: "ReqAuthorizationDate",
                    width: 120,
                },
                {
                    text: "T. Transcurrido [DD:HH:MM:SS]",
                    value: "ReqTimeElapsed",
                    width: 100,
                    align: "center"
                },
                {
                    text: "Req. Visita",
                    value: "RequiredVisit",
                    width: 20,
                },

            ],
        },
        value: null,
        itemsSAP: [],
        dialogLoad: false,
        dialogCotizar: false,
        dialogDispach: false,
        dialogReq:false,
        ReqID:null,
        order: {
            detail: []
        },
    }),
    created() {
        if (!this.$fun.isAdmin()) this.filter.AreID = this.$fun.getUserInfo().CtrContract.AreID;
        this.refresh()
    },

    watch: {
        itemsSAP() {
            console.log("itemsSAP", this.itemsSAP);
        },
        'filter.BeginDate'() {
            this.refresh();
        },
        'filter.EndDate'() {
            this.refresh();
        },

        'filter.AreID'() {
            this.refresh();
        },

        'filter.typeBusiness'() {
            this.refresh();
        },
        'filter.TypeEAR'() {
            this.refresh();
        },
        'filter.TypeRequirement'() {
            this.refresh();
        },
    },

    methods: {

        //Refrescar Grillaa
        refresh() {

            this.processing = true
            _sRequirementDetail.pagination({length:1000, filter: this.filter}, this.$fun.getUserID()).then((r) => {
                    if (r.status == 200) {
                        this.itemsDetail = r.data.data;
                        
                        this.processing = false
                        this.itemsSAP = [];
                    }

                },
                (e) => {
                    this.messageProcessing = e.response.data.Message;
                    this.processing = false;
                });
        },

        //Levantar Cotizar
        previewCotizar() {

            if (this.itemsSAP.length == 0) {
                this.$fun.alert("No ha seleccionado items", "warning")
                return
            }

            this.order.detail = this.itemsSAP;
            var countPurcharse = 0,
                countService = 0,
                distinct = 0

            for (let i = 0; i < this.order.detail.length; i++) {

                if (this.order.detail[i].TypeRequirement == 1) countPurcharse = countPurcharse + 1
                if (this.order.detail[i].TypeRequirement == 3) countService = countService + 1

                this.order.detail[i].OddMeasureUnit = this.order.detail[i].UnitMeasurement;
                this.order.detail[i].OddDescription = this.order.detail[i].DerDescription;
                this.order.detail[i].CtdDescription = this.order.detail[i].DerDescription;
                this.order.detail[i].CtdObservation = this.order.detail[i].DerObservation;
                this.order.detail[i].OddObservation = this.order.detail[i].DerObservation;
                this.order.detail[i].CtdQuantity = this.order.detail[i].DerQuantity;
                //this.order.detail[i].ReqDayExpiration = this.order.detail[i].ReqDayExpiration;

                this.order.detail[i].Line = i + 1;

            }

            this.itemsSAP.forEach(element => {
                if (element.ReqID !== this.itemsSAP[0].ReqID) {
                    distinct = distinct + 1
                }
            });

            if (distinct > 0) {
                this.$fun.alert("Ha seleccionado items de varios REQUERIMIENTOS, por favor verifique", "error")
                return
            }

            if (countPurcharse > 0 && countService > 0) {
                this.$fun.alert("Upps, no puede Cotizar con diferente tipo de REQUERIMIENTO, por favor verifique", "error")
                return
            } else {
                if (this.itemsSAP.length > 0) {
                    this.order.ReqID = this.itemsSAP[0].ReqID
                    this.order.TypeOrder = this.itemsSAP[0].TypeRequirement
                    this.dialogCotizar = true
                };
            }

            /* if (this.itemsSAP.length > 0) {
                this.dialogCotizar = true
            }; */
        },

        //Levantar Modal
        previewSap() {

            if (this.itemsSAP.length == 0) {
                this.$fun.alert("No ha seleccionado items", "warning")
                return
            }

            this.order.CttID = null
            this.order.detail = this.itemsSAP;

            var countPurcharse = 0,
                countService = 0,
                distinct = 0

            for (let i = 0; i < this.order.detail.length; i++) {
                if (this.order.detail[i].TypeRequirement == 1) countPurcharse = countPurcharse + 1
                if (this.order.detail[i].TypeRequirement == 3) countService = countService + 1

                this.order.detail[i].OddMeasureUnit = this.order.detail[i].UnitMeasurement;
                this.order.detail[i].OddDescription = this.order.detail[i].DerDescription;
                this.order.detail[i].OddObservation = this.order.detail[i].DerObservation;
                this.order.detail[i].OddQuantity = this.order.detail[i].DerQuantity;
                this.filter.TypeRequirement = this.order.detail[i].TypeRequirement
                this.order.detail[i].Line = i + 1;
            }

            this.itemsSAP.forEach(element => {
                if (element.ReqID !== this.itemsSAP[0].ReqID) {
                    distinct = distinct + 1
                }
            });

            if (distinct > 0) {
                this.$fun.alert("Ha seleccionado items de varios REQUERIMIENTOS, por favor verifique", "error")
                this.filter.TypeRequirement = null
                return
            }

            if (countPurcharse > 0 && countService > 0) {
                this.$fun.alert("Upps, no puede crear una OC/OS con diferente tipo de Requerimiento, por favor verifique", "error")
                this.filter.TypeRequirement = null
                return
            } else {
                if (this.itemsSAP.length > 0) {

                    this.order.CttID = null

                    this.order.ReqID = this.itemsSAP[0].ReqID
                    this.dialogLoad = true
                };
            }

            console.log("al cargar",this.itemsSAP)

            /* */

        },

        /* orderDispatch() {

            if (this.itemsSAP.length == 0) {
                this.$fun.alert("No ha seleccionado items", "warning")
                return
            }

            this.order.detail = this.itemsSAP;
            var countPurcharse = 0,
                countService = 0

            for (let i = 0; i < this.order.detail.length; i++) {
                if (this.order.detail[i].TypeRequirement == 1) countPurcharse = countPurcharse + 1
                if (this.order.detail[i].TypeRequirement == 3) countService = countService + 1
                this.order.detail[i].OddMeasureUnit = this.order.detail[i].UnitMeasurement;
                this.order.detail[i].OddDescription = this.order.detail[i].DerDescription;
                this.order.detail[i].OddObservation = this.order.detail[i].DerObservation;
                this.order.detail[i].OddQuantity = this.order.detail[i].DerQuantity;
                this.filter.TypeRequirement = this.order.detail[i].TypeRequirement
                this.order.detail[i].Line = i + 1;
            }

            if (countPurcharse > 0 && countService > 0) {
                this.$fun.alert("Upps, no puede realizar un despacho con diferente tipo de Requerimiento, por favor verifique", "error")
                return
            } else {
                if (this.itemsSAP.length > 0) {
                    this.dialogDispach = true
                };
            }
        }, */

        //Anular Item
        deleted(items) {
            items.map((item) => {
                item.DerStatus = 9;
                item.UsrUpdateID = this.$fun.getUserID()
                item.Details =[]
                return item;
            })
            this.$fun.alert('Seguro de Anular?', 'question').then((r) => {
                if (r.value) {
                    _sRequirementDetail.save(items, this.$fun.getUserID()).then(() => {
                        this.$fun.alert('Anulado Correctamente', 'success');
                        this.refresh();

                    });
                }
            });

        },

        openReq(item){
            if(item.ReqID > 0){
                this.ReqID = item.ReqID
                this.dialogReq = true
            }
        }
    },

};
</script>
